import React from "react";
import ContactPage from "../ContactPage.js";
import Footer from "../Footer.js";
import "../../App.css";

function Contact() {
  return (
    <>
      <ContactPage />
      <Footer />
    </>
  );
}

export default Contact;
