import React from "react";
import { Button } from "./Button";
import "../App.css";
import "./HeroSection.css";

function buttonLink() {
  window.open(
    "https://chirocat.com/innovative-health-solutions/bookings/types?user_id=16835",
    "_blank"
  );
  return 0;
}

function HeroSection() {
  return (
    <div className="hero-container">
      <video className="backGroundVideo" autoPlay loop muted>
        <source src="/videos/motionEffectBackground.MP4" type="video/mp4" />
      </video>
      <h1>A Journey to a Better You Begins Here</h1>
      <p>what are you waiting for?</p>
      <div className="hero-btns">
        <Button
          className="btns"
          buttonStyle="btn--outline"
          buttonSize="btn--large"
          onClick={buttonLink}
        >
          GET STARTED
        </Button>
      </div>
    </div>
  );
}

export default HeroSection;
