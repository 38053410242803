import React from "react";
import "./Contact.css";
import { Link } from "react-router-dom";

function ContactPage() {
  return (
    <div className="container">
      <img
        className="backgroundImage"
        src="images/ContactPageBG2.png"
        alt="loading..."
      ></img>
      <div className="info">
        <p>
          You can find us at{" "}
          <Link
            to={
              "https://www.google.com/maps/place/9295+N+Lilley+Rd,+Plymouth,+MI+48170/@42.3569433,-83.4606106,18.54z/data=!4m6!3m5!1s0x883b52d73ae62641:0xde69718ad9ff3dda!8m2!3d42.3569663!4d-83.4598598!16s%2Fg%2F11c2g5xn1s?entry=ttu"
            }
            target="_blank"
          >
            9295 N Lilley Rd Plymouth, MI 48170
          </Link>
        </p>
      </div>
    </div>
  );
}

export default ContactPage;
