import React from "react";
import "../../App.css";
import Footer from "../Footer";
import InformationPAGE from "../InformationPAGE";

function Information() {
  return (
    <>
      <InformationPAGE />
      <Footer />
    </>
  );
}

export default Information;
