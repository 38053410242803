import "./App.css";
import Navbar from "./components/Navbar";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import React from "react";
import Home from "./components/pages/Home";
import Contact from "./components/pages/Contact";
import SignUp from "./components/pages/SignUp";
import Products from "./components/pages/Products";
import { Helmet } from "react-helmet";
import stavGay from "./components/pages/stavGay";
import Information from "./components/pages/Information";

function App() {
  return (
    <>
      <Helmet>
        <title>MotionEffect</title>
        <meta name="description" content="MoitonEffect Health and Fitness" />
      </Helmet>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" exact Component={Home} />
          <Route path="/Contact" Component={Contact} />
          <Route path="/products" Component={Products} />
          <Route path="/SignUp" Component={SignUp} />
          <Route path="/stavGay" Component={stavGay} />
          <Route path="/Information" Component={Information} />
        </Routes>
      </Router>
      {/*TODO: fix path with typing in link*/}
    </>
  );
}

export default App;
