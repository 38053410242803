import React from "react";
import { Link } from "react-router-dom";
import "./InformationPAGE.css";
function InformationPAGE() {
  return (
    <>
      <div className="container">
        <div className="shadow Left">
          <img
            className="imgLeft"
            src="images/matImage.jpg"
            alt="loading..."
            align="right"
          ></img>
        </div>
        <div className="shadow Right">
          <img src="images/weights.jpg" alt="loading..." className="imgRight" />
        </div>
        <div className="mainBlock">
          <h1>Erin Brockenshire CPT, HMS</h1>
          <div className="Block1">
            <div className="Block1Text">
              Erin is a Certified Personal Trainer and human movement
              specialist. She also holds numerous coaching certifications along
              with decades of experience conditioning and training teams and
              herself. Finding ways to correct movement imbalances and
              compensations is a passion that stems from personal and
              professional experience
            </div>
          </div>
          <img
            className="headshot1"
            src="images/Headshot-3100ED.jpg"
            alt="loading..."
          ></img>

          <div className="outerTealBlock">
            <div className="BlockTEAL">
              <div className="tealBlockText">
                <p>
                  Motion Effect is a comprehensive Neuro Physical Therapy
                  training, using both traditional movement correction
                  techniques and when possible, the{" "}
                  <Link
                    to="https://neuro20.com/"
                    target="_blank"
                    className="neuro20Link"
                  >
                    {" "}
                    Neuro20{" "}
                  </Link>{" "}
                  therapy suit
                </p>
                <p>
                  <span>
                    {" "}
                    <Link
                      to="https://neuro20.com/"
                      target="_blank"
                      className="neuro20Link"
                    >
                      Neuro20
                    </Link>{" "}
                  </span>
                  is a whole Body electrical muscle stimulation smart suit,
                  designed to help the body recover from injury, re-pattern or
                  correct neuromuscular pathways, and upgrade human performance
                </p>
                <p>
                  The
                  <span>
                    {" "}
                    <Link
                      to="https://neuro20.com/"
                      target="_blank"
                      className="neuro20Link"
                    >
                      Neuro20
                    </Link>{" "}
                  </span>
                  suit, along with Erins assessment and knowledge, will help you
                  to re-educate muscle patterning and firing. other benefits may
                  include but are not limited to:
                </p>
                <ul>
                  <li>Increasing circulation</li>
                  <li>Maintaining and increasing functional range of motion</li>
                  <li>Preventing future injuries</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="block2">
            <p className="finalTextBlock">
              Neuro20 is the missing link in physical health restoration. With
              its advanced software system and smart electrical stimulation
              suit, Neuro20 has been a game changer for my team and for my
              clients! Coupled with a custom training session to correct
              movement changes and pattern disruptions, the e-stim reduces pain,
              swelling, and increases circulation. For acute issues we recommend
              two sessions a week in the Neuro20 suit to cut down your recovery
              time, by up to half the time. Depending on your needs, the Neuro20
              suit, along with our training, can help you accomplish your goals
              quickly with little to no stress on your joints. Are you ready to
              be the next success story?
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default InformationPAGE;
