import React from "react";
import "../../App.css";

export default function stavGay() {
  return (
    <h1>
      <img src="/images/orbitStav.gif" alt="loading..."></img>
      <img src="/images/StavSpin.gif" alt="loading..."></img>
    </h1>
    //TODO: finish implementing this
    //changes made
  );
}
