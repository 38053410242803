import React from "react";
import { Button } from "./Button";
import "./Footer.css";
import { Link } from "react-router-dom";
import emailList from "./emailList.txt";

function handleSubmit(e) {
  e.preventDefault();
  window.open("https://forms.gle/XKKtqJeEiqNo6bD9A", "_blank");
}

function Footer() {
  return (
    <div className="footer-container">
      <div className="footer-links">
        <div className="footer-link-wrapper">
          <h2>About us</h2>
          <div className="footer-link-items">
            <Link to="/">office phone: (734) 667-2273</Link>
            <Link to="/">cell: (734) 691-0197</Link>
            <Link to="https://innovativehealthdrs.com/">The Office</Link>
          </div>
          <div className="footer-link-items">
            <Link to="/">Email us at: erin.motioneffect@gmail.com</Link>
            <Link
              to={
                "https://www.google.com/maps/place/9295+N+Lilley+Rd,+Plymouth,+MI+48170/@42.3569433,-83.4606106,18.54z/data=!4m6!3m5!1s0x883b52d73ae62641:0xde69718ad9ff3dda!8m2!3d42.3569663!4d-83.4598598!16s%2Fg%2F11c2g5xn1s?entry=ttu"
              }
              target="_blank"
            >
              9295 N Lilley Rd Plymouth, MI 48170
            </Link>
          </div>
        </div>
      </div>
      <section className="social-media">
        <div className="social-media-wrap">
          <div className="footer-logo">
            <Link className="social-logo">
              <img src="images/logo1.png"></img>
            </Link>
          </div>
          <small className="website-rights">MotionEFFECT 2023</small>
          <div className="social-icons">
            <Link
              className="social-icon-link facebook"
              to="https://www.facebook.com/innovativehealthdrs"
              target="_blank"
              aria-label="Facebook"
            >
              <i className="fab fa-facebook-f"></i>
            </Link>
            <Link
              className="social-icon-link Instagram"
              to="https://www.instagram.com/innovativehealthsolutions/"
              target="_blank"
              aria-label="Instagram"
            >
              <i className="fab fa-instagram"></i>
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
